<script>
    import Squelette from './Squelette.svelte'
    import Loader from './Loader.svelte' 
</script>

<Squelette nav={false}>
    <div class="fr-p-2w fr-pb-10w">
        <Loader></Loader>
    </div>
</Squelette>
